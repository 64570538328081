<template>
  <section class="about container page-section  text-center bg-light">
    <b-card :title="$t('sobre.title')">
      <p class="text-justify">{{ $t("sobre.message1") }}</p>
      <p class="text-justify">
        {{ $t("sobre.message2")
        }}<a
          ><router-link to="/equipe">{{ $t("sobre.aqui") }}</router-link></a
        >.
      </p>
    </b-card>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.page-section {
  padding: 3rem 0;
}
</style>
